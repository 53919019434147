#skills {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

#skill-group-container {
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-evenly;
    gap: 20px;
}

.skill-group {
    min-width: 200px;
    max-width: calc(20% - 5px);
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.skill-group-list {
    list-style-type: none;
    padding: 0;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
}

@media screen and (max-width: 580px) {
    #skills {
        width: calc(100% - 10px);
    }

    #skill-group-container {
        padding: 0px 5px;
    }
}

@media screen and (max-width: 430px) {
    .skill-group {
        min-width: 0;
        max-width: 45%;
    }
}
