footer {
    width: 100%;
    padding: 50px 0;
    margin-top: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    background-color: black;

}

#contact-me, #socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    color: white;

    > h3 {
        cursor: default;
    }

    > a {
        text-decoration: none;
        color:  hsl(0, 0%, 50%);
    }
}
