#my-work {
    width: 95%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

#projects-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    gap: 20px;
}

@media screen and (max-width: 510px) {
    #my-work {
        padding: 0 5px;
    }
}
