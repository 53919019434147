#about-me {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    
    > img {
        width: 200px;
        clip-path: circle();
    }
}

#about-me-content {
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

#download-resume {
    cursor: pointer;
}

@media screen and (max-width: 488px) {
    #about-me {
        width: 100%;
        padding: 0 5px;
        flex-direction: column;
    }

    #about-me-content {
        width: 100%;
        padding: 0 10px;
    }
}
