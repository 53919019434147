nav {
    width: 100%;
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    background-color: black;
    
    > a {
        text-decoration: none;
        color: white;
    }
}

#nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    > a {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
}

#view-resume-button {
    padding: 6px 12px;
    cursor: pointer;
    
    border-radius: 6px;
    background-color: red;
    color: white;
}

@media screen and (max-width: 450px) {
    nav {
        padding: 20px 15px;
    }

    .hidden-on-mobile {
        display: none;
    }
}
