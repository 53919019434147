.project-card {
    width: 600px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 15px;
}

.project-card-link-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-self: center;
}

.project-link {
    cursor: pointer;
}

@media screen and (max-width: 610px) {
    .project-card {
        width: 100vw;
    }
}
